import HeroBar from "./HeroBar"
import MobileMenu from "./MobileMenu"
import React from "react"

import { Link } from "gatsby"

import './team-member.scss';

const TeamMember = ({ children, headshot, name, position }) =>
        <div class="team-member">
    <MobileMenu>
        <Link to=".">Team Member</Link>
        <Link to="/">Home</Link>
        <Link to="/contact">Contact</Link>
        <Link to="/team">Team</Link>
    </MobileMenu>
    <HeroBar />
    <div class="columns">
        <div class="headshot-column">{headshot}</div>
        <div class="content">
            <div class="team-member-one-line-header">
                <h1>{name}</h1>&nbsp;|&nbsp;<h2>{position}</h2>
                <hr/>
            </div>
            <div class="team-member-two-line-header">
                <h1>{name}</h1>
                <h2>{position}</h2>
                <hr/>
            </div>
            {children}
            <Link class="back-to-team" to="/team">Back to Team</Link>
        </div>
    </div>
</div>

export default TeamMember;
