import BandwagonDotCom from "../../components/BandwagonDotCom"
import React from "react"
import TeamMember from "../../components/TeamMember"

import { StaticImage } from "gatsby-plugin-image"

const CrystalLaGrone = () => <BandwagonDotCom>
  <TeamMember
        headshot=<StaticImage src="../../images/crystal-lagrone-headshot.png"
                alt="Crystal LaGrone" />
        name="Crystal LaGrone"
        position="E-Governance Specialist"
  >
      <p>
        Crystal is an e-governance and account management specialist. After
        receiving her master’s degree in Tallinn, Estonia, Crystal brought her
        passion for the field of e-governance to the US. Her emphasis is on the
        use of innovative digital solutions that promote security, transparency,
        and usability.
      </p>
      <h3>Fun Fact</h3>
      <hr/>
      <p>
        Crystal sang with an Estonian women’s choir for two seasons, and still
        practices Estonian daily.
      </p>
      <h3>Why I love Bandwagon</h3>
      <hr/>
      <p>
        THE PEOPLE are definitely the reason I wanted to join the team. They see
        the value in each individual and give them an opportunity to grow.
        Bandwagon offers the chance to work with cutting-edge technology to help
        create a better system to protect people’s data.
      </p>
  </TeamMember>
</BandwagonDotCom>;

export default CrystalLaGrone
